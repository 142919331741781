import React, { useState, useEffect } from "react"
import ReactModal from "react-modal"
import { ReactComponent as CloseBtn } from "../assets/close.svg"
import { gtmButtonPushEvent } from "../gtm"

ReactModal.setAppElement(document.getElementById("root"))

function Modal({ btnText, load, children }) {
    const [open, setOpen] = useState(false)

    useEffect(() => {
      if (load) setOpen(true)
    }, [])


    const handleOpen = () => {
        setOpen(true)
        gtmButtonPushEvent("Engagement", "Popup Click", btnText)
    }
    const handleClose = () => setOpen(false)

    return (
        <div className="modal">
            <button onClick={handleOpen}>{btnText}</button>
            <ReactModal
                closeTimeoutMS={150}
                overlayClassName="modal__overlay"
                className="modal modal__body"
                isOpen={open}
                onRequestClose={handleClose}
                contentLabel={btnText}
                closeTimeoutMS={300}
            >
                <CloseBtn className="modal__close-btn" onClick={handleClose} />
                {children}
            </ReactModal>
        </div>
    )
}

export default Modal