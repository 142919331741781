import { ResourcesActions } from "../actions/types"
const { RESOURCES_TOGGLE } = ResourcesActions

const initialState = {
    toggle: true
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESOURCES_TOGGLE:
            return {
                ...state,
                toggle: action.payload
            }
        default:
            return state
    }
}