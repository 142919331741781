import React from "react"

function Header() {
    return (
        <header className="grid__header header">
            <div className="header__logo">SCAD</div>
            <div>Rising Star Orientation</div>
        </header>
    )
}

export default Header
