import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { gtmPushPageVisit, gtmButtonPushEvent } from "../gtm"
import BorderDesktop from '../assets/Wavy-Edge-Desktop.png'
import BorderMobile from '../assets/Wavy-Edge-Mobile.png'

function Splash() {
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)
    useEffect(() => {
        gtmPushPageVisit('/', 'Splash')
    }, [])

    return (
        <div className="splash">
          <div className="splash__content">
            <div className="splash__content--inner">
              <p className="splash__welcome">Welcome to SCAD</p>
              <h1>Rising Star <br /> Orientation</h1>
              <div className="splash__content--container">
                  {/* <p className="splash__launch">Experience launches Monday, Aug. 29.</p> */}
                  <Link className="splash__enter-btn" to="/presentations">
                    Enter
                  </Link>
              </div>
            </div>
            <img className="splash__border--desktop" src={BorderDesktop}  alt=""/>
            <img className="splash__border--mobile" src={BorderMobile}  alt=""/>
          </div>
          <div className="splash__img"></div>
        </div>
    )
}

export default Splash
