import React, { useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useLocation } from 'react-router-dom';
import { gtmPushPageVisit, gtmButtonPushEvent } from "../../gtm"
import CompleteImage from '../../assets/Rising-Star-Summer-2017-HS-134-SN.jpeg'
import LeftStars from '../../assets/Left-Stars.png'
import RightStars from '../../assets/Right-Stars.png'
import JotFormReact from 'jotform-react'

function Complete() {
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)
    const handleVideoClick = () => gtmButtonPushEvent("Engagement", "Video Click", "SCAD 2020 Orientation: Complete orientation")
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const formComplete = searchParams.get('complete')

    useEffect(() => {
        gtmPushPageVisit('/presentations/complete-orientation', 'Complete orientation')
    }, [])

    return (
        <>
            <div className="presentations">
                <motion.div initial={{opacity: 0 }} animate={{ opacity: 1 }}>
                    {/* <div className="presentations__embed-container" onClick={handleVideoClick}>
                        <div className="presentations__embed-wrapper" />
                        <iframe title="SCAD 2020 Orientation: Student Life" className="presentations__embed-item" src="https://www.youtube.com/embed/Onnont1T55o"></iframe>
                    </div> */}
                    <img className="presentations__img" src={CompleteImage}  alt=""/>
                    <div className="presentations__content-container">
                        <h2>Complete orientation</h2>
                        <p>We’re delighted to welcome you to SCAD this summer. Once you have reviewed all sections of this Rising Star virtual orientation, confirm that you have completed the experience by submitting the following information.</p>

                        {!formComplete && (
                          <JotFormReact
                            formURL="https://scad.jotform.com/241074842645155"
                            formID="241074842645155"
                            autoResize={true}
                          />
                        )}

                        {formComplete && (
                          <div className="thankyou thankyou__container">
                            <h2>Thank You!</h2>
                            <p>Your submission has been received.</p>
                            <p>We look forward to seeing you in June <br /> at Rising Star!</p>
                            <img className="thankyou__left-image" src={LeftStars} alt="" />
                            <img className="thankyou__right-image" src={RightStars} alt="" />
                          </div>
                        )}
                    </div>
                </motion.div>
            </div>
        </>
    )
}

export default Complete
