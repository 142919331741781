import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import Modal from "../Modal"
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg"
import { gtmPushPageVisit, gtmButtonPushEvent } from "../../gtm"
import { motion, AnimatePresence } from "framer-motion"
import AcademicImage from '../../assets/academic-advising-page.png'

function StudentAccount() {
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)
    const handleVideoClick = () => gtmButtonPushEvent("Engagement", "Video Click", "SCAD 2020 Orientation: Academics & Advising")

    useEffect(() => {
        gtmPushPageVisit('/presentations/academics-advising', 'Academics advising')
    }, [])

    return (
        <>
            <div className="presentations">
                <motion.div initial={{opacity: 0 }} animate={{ opacity: 1 }}>
                    {/* <div className="presentations__embed-container" onClick={handleVideoClick}>
                        <div className="presentations__embed-wrapper" />
                        <iframe title="SCAD 2020 Orientation: Academics & Advising" className="presentations__embed-item" src="https://www.youtube.com/embed/hqGzWVC8gcw"></iframe>
                    </div> */}
                    <img className="presentations__img" src={AcademicImage}  alt=""/>
                    <div className="presentations__content-container">
                        <h2>Academics and advising</h2>
                        <p>Get ready to start the quarter with these important dates, policies, resources, and more.</p>
                        <p><strong>Learn more about:</strong></p>
                        <ul className="presentations__learn-more-list">
                            <li>
                                <Modal btnText="MySCAD">
                                    <h3>MySCAD</h3>
                                    <p>You'll find a wealth of information on <a href="https://myscad.scad.edu/" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://myscad.scad.edu/')}>MySCAD</a>, from the Virtual Lecture Hall to the latest tools, techniques, and tutorials on LinkedIn Learning. It's also your portal to academic resources, news, and events, and your main channel to connect with the university, sign up for meal plans, and make advising appointments.</p>
                                    <p>MySCAD also offers access to course registration, SCAD email, financial services accounts, posted grades, course assignment submissions, online test administration, Family Educational Rights and Privacy Act (FERPA) management, important announcements, and more. Students are prohibited from sharing their MySCAD passwords with parents or others. However, parents may appropriately access their student's financial services account and make payments via TouchNet.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="SCAD app">
                                    <h3>SCAD app</h3>
                                    <p><a href="https://www.scad.edu/mobile" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/mobile')}>Use the SCAD app</a> to get info about university programs, events, exhibitions, and more. You can also:</p>
                                    <ul>
                                        <li>Check meal plan balances.</li>
                                        <li>Search for course information and grades.</li>
                                        <li>Look up a professor's email address.</li>
                                        <li>Check out the menu at your favorite dining hall.</li>
                                        <li>Find out where SCAD buildings are located.</li>
                                        <li>Look up classroom technology resources.</li>
                                    </ul>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Drop/add deadline">
                                    <h3>Drop/add deadline</h3>
                                    <p>The drop/add period is always the first five business days of each quarter. Schedule changes have tuition and attendance impacts, so talk to your student success adviser first.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Attendance policy">
                                    <h3>Attendance policy</h3>
                                    <p>You are permitted to miss only four class sessions in each course. If you miss more than four sessions, you'll be unable to pass the class. Use your absences wisely. There are no excused absences. Classes missed due to late registration or schedule changes are included in the overall absences allowed.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Advising">
                                    <h3>Advising</h3>
                                    <p>You’ll be assigned a student success adviser who will work closely with you to develop an academic strategy based on your intended major.</p>
                                    <h4>Student success adviser</h4>
                                    <p>Meet your student success adviser to learn what to expect. Your adviser can answer all your questions and provide helpful academic advice.</p>
                                    <h4>Appointments</h4>
                                    <p>Use the online scheduling system available in <a href="https://myscad.scad.edu/" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://myscad.scad.edu/')}>MySCAD</a> to make an appointment with your student success adviser. During peak periods of the quarter, such as drop/add and registration weeks, advisers are also available on a walk-in basis. You can check walk-in advising times by visiting MySCAD > Student Workspace > Undergraduate Advising > "Undergraduate Walk-in and Virtual Drop-in Schedule" link.</p>
                                    <p>To schedule an appointment, visit the MySCAD > Student Workspace tab > Undergraduate Advising channel > “Make an appointment” link. When scheduling an appointment, select the reason you need to meet (e.g., registration questions, study abroad, graduation planning, etc.) and provide any relevant details so that your adviser can prepare for your appointment.</p>
                                    <p>Learn more about your and your adviser's responsibilities in your program guide.</p>
                                    <p>
                                        <strong>Atlanta</strong> <br />
                                        <a href="mailto:advisement@scad.edu">advisement@scad.edu</a> | 912.525.5820 <br />
                                            Student Success Center | 1600 Peachtree St. NW
                                        </p>
                                    <p>
                                        <strong>Savannah</strong> <br />
                                        <a href="mailto:advisement@scad.edu">advisement@scad.edu</a> | 912.525.5820 <br />
                                            Bradley Hall | 115 E. York St.
                                        </p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Privacy">
                                    <h3>Privacy</h3>
                                    <p>The Family Educational Rights and Privacy Act, or FERPA, affords students certain rights with respect to their education records. This means you can request to read your education records and propose amendments if you believe there's misleading or inaccurate information. It also means you're able to consent or not consent to the disclosure of personal information that is allowed under FERPA.</p>
                                    <p>If your family member makes most of the payments on your account and will need to know your current account balance, sign a FERPA waiver so that university staff members are allowed to discuss your account balance with that person. Manage your FERPA preferences by navigating to MySCAD &gt; Resources tab &gt; SCAD Forms channel. Enter “FERPA” in the search field and click the “FERPA Management” link.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Student accounts">
                                    <h3>Student accounts</h3>
                                    <p>Your student account is where your charges for tuition, housing, meal plans, and any other fees are posted and where payments are recorded. You are responsible for making any changes in name or address that may affect when or where you get your account information via your <a href="https://myscad.scad.edu/" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://myscad.scad.edu/')}>MySCAD</a> account. Your success adviser is available to help, if needed.</p>
                                    <p>Your parents or other family members will not be sent a physical copy of your account activity, so if a parent or other family member is making payments on your account, you should add that person as an authorized user in TouchNet. Contact your student success adviser with any account questions, especially if you think you'll make enrollment or housing changes, to see how that may impact your account.</p>
                                    <p className="modal__list-label">To make online payments</p>
                                    <ul>
                                        <li>Log in to MySCAD.</li>
                                        <li>Select the My Info tab.</li>
                                        <li>Locate the Student Account channel.</li>
                                        <li>Select “Make a Payment – US$.”</li>
                                    </ul>
                                    <p className="modal__list-label">To set up a parent or family member as an authorized user</p>
                                    <ul>
                                        <li>Log in to MySCAD.</li>
                                        <li>Select the My Info tab.</li>
                                        <li>Locate the Student Account channel.</li>
                                        <li>Click “My Student Account Online” to open TouchNet in a new window.</li>
                                        <li>Click “Authorized Users” link in the menu bar.</li>
                                        <li>Enter the email address of the family member or other contact and follow the instructions listed.</li>
                                        <li>Two emails will be sent to the new authorized user, which allow the user access to the student account online.</li>
                                    </ul>
                                    <h4>Scholarships</h4>
                                    <p>SCAD awards nearly $100 million in scholarships to undergraduate students each year based on academic achievement, need, or both. Scholarships may be renewed quarterly as long as you remain enrolled and maintain a cumulative GPA of 3.0 or higher for undergraduate students and a GPA of 3.25 or higher for graduate students. Increasing or decreasing the number of classes in which you enroll, as well as living off campus, can affect your scholarships, so consult an adviser before making any changes.</p>
                                    <p>Scholarships are not available for Rising Star.</p>
                                </Modal>
                            </li>
                        </ul>
                    </div>
                </motion.div>
            </div>
            <div className="presentations__bottom">
                <Link className="presentations__next-btn" to="/presentations/career-prep">
                    <div>
                        <div className="presentations__next-btn-text--top">Next: Section 2</div>
                        <div className="presentations__next-btn-text--bottom">Career prep</div>
                    </div>
                    <ArrowIcon />
                </Link>
            </div>
        </>
    )
}

export default StudentAccount
