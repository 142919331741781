import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useParams, NavLink, useHistory, Link } from "react-router-dom"
import Pages, { config } from "./pages"
import MobileAccordion from "../MobileAccordion"
import { ReactComponent as FlagIcon } from "../../assets/flag.svg"
import Resources from "../Resources"

function Presentations({ isMobile }) {
    const { page } = useParams()
    const history = useHistory()
    const pageConfig = () => {
        let index = config.findIndex(item => item.link === page)
        if (index === -1) {
            index = 0
            history.push("/presentations/welcome")
        }
        return index;
    }
    const [pageIndex, setPageIndex] = useState(pageConfig())
    const [expanded, setExpanded] = useState(0)
    const showVeil = false


    useEffect(() => {
        let index = pageConfig()
        setPageIndex(index)
        setExpanded(index)
    }, [page])

    if (isMobile) {
        return (
            <main className="grid__main">
                <div className={`presentations__mobile-bg ${showVeil ? 'presentations__mobile-bg--veil' : ''}`}>
                    <h1>Orientation presentations</h1>
                    <p>Maximize orientation and start the quarter off strong. Complete all presentations before you arrive to campus.</p>
                    <div className="presentations__accordion">
                        {config.map((item, i) => (
                            <div key={`accordion-mobile-${i}`} className="mobile-accordion">
                                <MobileAccordion
                                    i={i}
                                    iconTitle={i === 0 ? 'hello.' : null}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    title={item.text}
                                    location={`/presentations/${item.link}`}
                                    link={item.link}
                                >
                                    <Pages component={config[i].component} />
                                </MobileAccordion>
                            </div>
                        ))}
                    </div>
                </div>
                {showVeil && (
                  <div className="presentations__veil">
                    <div>
                      <div className="presentations__hello--alt">hello.</div>
                      <h1>We’re getting ready for Fall 2022</h1>
                      <p>
                        Come back soon for the complete virtual experience. In the meantime, check out your <Link className="" to="/schedule">orientation schedule</Link>.
                      </p>
                    </div>
                  </div>
                )}
                <Resources />
            </main>
        )
    } else {
        return (
            <main className="grid__main">
                <div className="grid__side-bar">
                    <h1>Rising Star</h1>
                    <p>Maximize your Rising Star experience and review the following information before you arrive.</p>
                    {config.map((item, i) => (
                        <div key={`/presentations-navlink-${i}`}>
                            <NavLink
                                to={`/presentations/${item.link}`}
                                className="presentations__link"
                                activeClassName="presentations__selected"
                            >
                                {i === 0
                                    ? (
                                        <div className="presentations__link-icon--hello">hello.</div>
                                    )
                                    : (
                                        <div className="presentations__link-icon">{i}</div>
                                    )}
                                {item.text}
                            </NavLink>
                        </div>
                    ))}
                </div>
                <div className={`grid__content ${pageIndex === config.length - 1 ? 'presentations--last-page' : ''}`}>
                    <Pages component={config[pageIndex].component} />
                </div>
                {showVeil && (
                  <div className="presentations__veil">
                    <div>
                      <div className="presentations__hello--alt">hello.</div>
                      <h1>We’re getting ready for Fall 2022</h1>
                      <p>
                      Come back Monday, Aug. 29 for the complete virtual experience. In the meantime, check out your <Link className="" to="/schedule">orientation schedule</Link>.
                      </p>
                    </div>
                  </div>
                )}
            </main>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.mobile.isMobile
})

export default connect(mapStateToProps)(Presentations)
