import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import Modal from "../Modal"
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg"
import { gtmPushPageVisit, gtmButtonPushEvent } from "../../gtm"
import { motion, AnimatePresence } from "framer-motion"
import CareerImage from '../../assets/career-page.png'

function CareerPrep() {
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)
    const handleVideoClick = () => gtmButtonPushEvent("Engagement", "Video Click", "SCAD 2020 Orientation: Career Prep")

    useEffect(() => {
        gtmPushPageVisit('/presentations/career-prep', 'career-prep')
    }, [])

    return (
        <>
            <div className="presentations">
                <motion.div initial={{opacity: 0 }} animate={{ opacity: 1 }}>
                    {/* <div className="presentations__embed-container" onClick={handleVideoClick}>
                        <div className="presentations__embed-wrapper" />
                        <iframe title="SCAD 2020 Orientation: Career Prep" className="presentations__embed-item" src="https://www.youtube.com/embed/KIxAlqN1dhE"></iframe>
                    </div> */}
                    <img className="presentations__img" src={CareerImage}  alt=""/>
                    <div className="presentations__content-container">
                        <h2>Career prep</h2>
                        <p>See how SCAD helps you exceed your artistic and academic goals and achieve the career of your dreams.</p>
                        <p><strong>Learn more about:</strong></p>
                        <ul className="presentations__learn-more-list">
                            <li>
                                <Modal btnText="Career and alumni success">
                                    <h3>Career and alumni success</h3>
                                    <p>At SCAD, you’ll discover your interests and strengths. While you explore, and even after you’ve graduated, the office for career and alumni success can help you meet your goals. You’ll get guidance on choosing a major and a career path, finding organizations that match your talents, securing internships and jobs, and more.</p>
                                    <h4>Career adviser</h4>
                                    <p>While you’re working toward obtaining your degree, your career adviser will help you complete your own personal career action plan that includes professional readiness, self-promotion, portfolio development, internships, and post-graduation career success.</p>
                                    <p>
                                        <strong>Atlanta</strong> <br />
                                        <a href="mailto:careersatl@scad.edu">careersatl@scad.edu</a> | 404.253.5437 <br />
                                            Student Success Center | 1600 Peachtree St. NW
                                        </p>
                                    <p>
                                        <strong>Savannah</strong> <br />
                                        <a href="mailto:careers@scad.edu">careers@scad.edu</a> | 912.525.4653 <br />
                                        Deloitte Foundry | 516 Drayton St.
                                        </p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Career events">
                                    <h3>Career events</h3>
                                    <p>Take advantage of these career events and land your next internship or dream job.</p>
                                    <h4>Workshops</h4>
                                    <p>Create a knockout résumé, prep your portfolio, and perfect your pitch. Workshops organized by the office for career and alumni success are full of topics, tips, and tidbits that you can put into action. Check out upcoming workshops in the <a href="https://www.scad.edu/jobportal" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/jobportal')}>SCAD Job Portal</a>.</p>
                                    <h4>Employer visits</h4>
                                    <p>Top companies, brands, and organizations from around the world recruit at SCAD. Gain insight into the field of your choice and interview for elite positions.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="SCAD Job Portal">
                                    <h3>SCAD Job Portal</h3>
                                    <p>Each quarter, employers post thousands of job and internship opportunities on the <a href="https://www.scad.edu/jobportal" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/jobportal')}>SCAD Job Portal</a>. It’s also your one-stop location for scheduling an appointment with your career adviser, applying for on-campus interviews, and signing up for workshops. Upload an online résumé and portfolio to start getting recruited. Access the SCAD Job Portal using your MySCAD credentials.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="SCADpro">
                                    <h3>SCADpro</h3>
                                    <p>Want to get hands-on experience with the world’s leading brands and build an impressive portfolio, all before graduation? Participate in <a href="https://www.scad.edu/about/scadpro" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/about/scadpro')}>SCADpro</a>, a collaborative innovation studio where Fortune 500 industry partners work side by side with students and faculty mentors to solve real-world problems. Each year, SCADpro generates nearly 40 distinct projects that tap into the talents of students across all disciplines to transform vision into reality for companies like Amazon, Google, L’Oréal, NASA, and more.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="SCADamp">
                                    <h3>SCADamp</h3>
                                    <p><a href="https://www.scad.edu/about/scadamp" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/about/scadamp')}>SCADamp</a> is a professional presentation studio that prepares creatives to amplify their stories through verbal, visual, and interpersonal communication skills. Guided by these three essential elements of professional communication, SCADamp empowers you to capitalize on career-making, peak-performance moments. SCADamp studios offer a comprehensive array of workshops, events, and coaching — open to all SCAD students, faculty, and staff at no charge. All SCADamp programming is delivered by credentialed and experienced communication, visualization, and connection coaches.</p>
                                </Modal>
                            </li>
                        </ul>
                    </div>
                </motion.div>
            </div>
            <div className="presentations__bottom">
                <Link className="presentations__next-btn" to="/presentations/student-life">
                    <div>
                        <div className="presentations__next-btn-text--top">Next: Section 3</div>
                        <div className="presentations__next-btn-text--bottom">Student life</div>
                    </div>
                    <ArrowIcon />
                </Link>
            </div>
        </>
    )
}

export default CareerPrep
