import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import Arrow from "../assets/accordion_arrow.png"

function Accordion({ i, expanded, setExpanded, title, children }) {
    const isOpen = i === expanded;
    const handleClick = () => setExpanded(isOpen ? false : i)

    return (
        <div className="accordion">
            <h2 className="accordion__header">
                <button aria-expanded={isOpen} className="accordion__btn" onClick={handleClick}>
                    <motion.img 
                        src={Arrow} 
                        alt=""
                        animate={{ rotateZ: isOpen ? -90 : 0 }}
                    >
                    </motion.img>
                    {title}
                </button>
            </h2>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        key="content"
                        className="accordion__body"
                        hidden={!isOpen}
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                    >
                        <div className="accordion__container--body">
                            {children}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default Accordion