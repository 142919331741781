import React from "react"
import Welcome from "./Welcome"
import StudentAccount from "./StudentAccount"
import CareerPrep from "./CareerPrep"
import StudentLife from "./StudentLife"
import Community from "./Community"
import International from "./International"
import Complete from "./Complete"

export const config = [
    {
        link: 'welcome',
        text: 'Welcome to Rising Star',
        component: "Welcome"
    },
    {
        link: 'academics-advising',
        text: 'Academics and advising',
        component: "StudentAccount"
    },
    {
        link: 'career-prep',
        text: 'Career prep',
        component: "CareerPrep"
    },
    {
        link: 'student-life',
        text: 'Student life',
        component: "StudentLife"
    },
    {
        link: 'community-expectations-and-safety-resources',
        text: 'Community expectations and safety resources',
        component: "Community"
    },
    {
        link: 'international-student-services-office',
        text: 'International Student Services Office (ISSO)',
        component: "International"
    },
    {
      link: 'complete-orientation',
      text: 'Complete orientation',
      component: "Complete"
  },
]

export default function Pages({ component }) {

    switch (component) {
        case "Welcome":
            return <Welcome />
        case "StudentAccount":
            return <StudentAccount />
        case "CareerPrep":
            return <CareerPrep />
        case "StudentLife":
            return <StudentLife />
        case "Community":
            return <Community />
        case "International":
            return <International />
        case "Complete":
            return <Complete />
        default:
            return <Welcome />
    }
}