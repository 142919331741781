import React from "react"
import { connect } from "react-redux"
import Header from "../components/Header"
import Resources from "../components/Resources"

function RisingStar({ children, isMobile }) {
    return (
        <div className="grid">
            <Header />
            <div className="grid__body">
                <div className="grid__body-container">
                    {children}
                </div>
                {!isMobile && <Resources />}
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    isMobile: state.mobile.isMobile
})

export default connect(mapStateToProps)(RisingStar)