import { MobileActions } from "../actions/types"
const { IS_MOBILE } = MobileActions

const initialState = {
    isMobile: undefined
}

export default function (state = initialState, action) {
    switch (action.type) {
        case IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload
            }
        default:
            return state
    }
}