import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useHistory } from "react-router-dom"
import { ReactComponent as FlagIcon } from "../assets/flag.svg"

function MobileAccordion({ i, expanded, setExpanded, title, link, location, children, iconTitle, iconClass }) {
    const isOpen = i === expanded;
    const history = useHistory();
    const handleClick = () => {
        history.push(location)
        setExpanded(isOpen ? false : i)
    }

    return (
        <>
            <h2 id={link} className="mobile-accordion__header">
                <motion.button
                    aria-expanded={isOpen}
                    initial={false}
                    animate={{ backgroundColor: isOpen ? "#FFFFFF" : "rgba(255,255,255,0.10)" }}
                    className="mobile-accordion__btn"
                    onClick={handleClick}
                >
                    {iconTitle
                        ? (
                            <div className={`${iconClass ? iconClass : 'presentations__link-icon--hello'}`}>{iconTitle}</div>
                        )
                        : (
                            <div className="presentations__link-icon">{i}</div>
                        )}
                    {title}
                </motion.button>
            </h2>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        key="content"
                        className="mobile-accordion__body"
                        hidden={!isOpen}
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default MobileAccordion