import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { toggleResources } from "../actions/resourcesActions"
import { useLocation, Redirect } from "react-router-dom"
import { motion } from "framer-motion"
import { ReactComponent as MinimizeIcon } from "../assets/resources_arrow.svg"
import { gtmPushPageVisit, gtmButtonPushEvent } from "../gtm"
import Accordion from "./Accordion"

function ResourcesContent({ isMobile, toggleResources, toggle }) {
  const [expanded, setExpanded] = useState(0)
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)

    return (
        <>
            <hr className="resources__hr--inner" />
            <h2>Orientation resources </h2>
            <p>Use these resources to kick off your creative journey at SCAD Rising Star.</p>
            <p>Start with the pre-arrival guide, review the Rising Star checklist, and explore this site to learn more about life at Rising Star and life as a SCAD student.</p>

            <Accordion
                    i={0}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    title="Rising Star pre-arrival guide"
                >
                    <ul className="resources__list">
                      <li><a href="/pdfs/rising-star-atlanta-guide.pdf" target="_blank">Atlanta guide</a></li>
                      <li><a href="/pdfs/rising-star-savannah-guide.pdf" target="_blank">Savannah guide</a></li>
                    </ul>
                </Accordion>
                {/* <Accordion
                    i={1}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    title="Rising Star checklist"
                >
                    <ul className="resources__list">
                      <li><a href="/pdfs/rising-star-atlanta-checklist.pdf" target="_blank">Atlanta checklist</a></li>
                      <li><a href="/pdfs/rising-star-savannah-checklist.pdf" target="_blank">Savannah checklist</a></li>
                    </ul>
                </Accordion> */}
        </>
    )
}

function Resources({ toggle, isMobile, toggleResources }) {
    let location = useLocation()
    let [width, setWidth] = useState(50)

    useEffect(() => {
        isMobile ? gtmPushPageVisit('/resources', 'resources') : gtmButtonPushEvent("Engagement", "Popup Click", 'resources')
    }, [])

    let toggleOn = () => toggle ? setWidth('auto') : setWidth(50)

    if (location.pathname === "/resources" && !isMobile && isMobile !== undefined) {
        toggleResources(true);
        return <Redirect to="/presentations/welcome" />
    }

    if (isMobile) {
        return (
            <motion.div
                className="grid__resources grid__resources--mobile"
                animate={{
                    top: toggle ? 'auto' : 0,
                    height: toggle ? 70 : '100%',
                    overflow: toggle ? 'hidden' : 'auto'
                }}
                transition={{ type: 'tween' }}
            >
                <motion.div className="resources__mobile-title"
                    animate={{
                        backgroundColor: toggle ? 'rgb(255, 255, 255)' : 'rgb(108, 65, 155)'
                    }}
                >
                    <motion.button
                        className="resources__close-btn"
                        onClick={() => toggleResources(!toggle)}
                        transition={{ type: 'tween' }}
                        onTap={toggleOn}
                    >
                        <motion.div
                            className="resources__icon-container"
                            animate={{ rotateZ: toggle ? 270 : 90, stroke: toggle ? '#41A29B' : '#fff' }}
                            transition={{ type: 'tween' }}
                        >
                            <MinimizeIcon />
                        </motion.div>
                        <motion.div
                            className="resources__label"
                            animate={{ color: toggle ? '#000' : '#fff' }}
                        >
                            {toggle ? 'Orientation resources' : 'Minimize panel'}
                        </motion.div>
                    </motion.button>
                </motion.div>
                <div className="resources__mobile-body">
                    <ResourcesContent
                        isMobile={isMobile}
                        toggleResources={toggleResources}
                        toggle={toggle}
                    />
                </div>
            </motion.div>
        )
    } else {
        return (
            <motion.div
                className={`grid__resources ${toggle ? 'resources--opened' : 'resources--closed'}`}
                initial={{ width: 50 }}
                animate={{ width }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
                <div className="resources">
                    <div className="resources__container">

                        {!isMobile && (
                            <motion.button
                                className="resources__close-btn resources__close-btn--closed"
                                onClick={() => toggleResources(!toggle)}
                                transition={{ type: 'tween' }}
                                onTap={toggleOn}
                            >
                                <motion.div
                                    animate={{ rotateZ: toggle ? 180 : 0 }}
                                    transition={{ type: 'tween' }}
                                    className="resources__icon"
                                >
                                    <MinimizeIcon />
                                </motion.div>
                                <div className="resources__hr">
                                    <hr />
                                </div>
                                <motion.div
                                    className="resources__label"
                                    animate={{ opacity: 1 }}
                                >
                                    Orientation resources
                                </motion.div>
                            </motion.button>
                        )}

                        <motion.div animate={{ opacity: toggle ? 0 : 1 }}>
                            <motion.button
                                className="resources__close-btn resources__close-btn--opened"
                                onClick={() => toggleResources(!toggle)}
                                animate={{ width: 40 }}
                                whileHover={{ width: 170, paddingLeft: 15 }}
                                transition={{ type: 'tween' }}
                                onTap={toggleOn}
                            >
                                <motion.div
                                    className="resources__close-btn--opened--container"
                                    animate={{ rotateZ: toggle ? 180 : 0 }}
                                    transition={{ type: 'tween' }}
                                >
                                    <MinimizeIcon />
                                    <span><strong>Minimize panel</strong></span>
                                </motion.div>
                            </motion.button>

                            <ResourcesContent
                                isMobile={isMobile}
                                toggleResources={toggleResources}
                                toggle={toggle}
                            />
                        </motion.div>


                    </div>
                </div>
            </motion.div>
        )
    }
}

const mapStateToProps = state => ({
    toggle: state.resources.toggle,
    isMobile: state.mobile.isMobile
})

const mapDispatchToProps = dispatch => ({
    toggleResources: value => dispatch(toggleResources(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Resources)
