import React, { useEffect } from 'react'
import { Route, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { updateDevice } from "./actions/mobileActions"
import Presentations from "./components/Presentations/"
import Resources from "./components/Resources"
import Splash from "./components/Splash"
import RisingStar from "./theme/RisingStar"
import Checklist from "./components/Checklist"
import "./styles/main.scss"

const Theme = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            <Layout>
                <Component {...props} />
            </Layout>
        )}
    />
);

function App(props) {

    let timeoutId;
    let checklist = false

    useEffect(() => {
        device()
        window.addEventListener("resize", resize)
        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [])

    const resize = () => {
        if (timeoutId) clearInterval(timeoutId)
        timeoutId = setTimeout(device, 200)
    }

    const device = () => {
        const width = window.innerWidth
        if (width <= 950) {
            props.updateDevice(true)
        } else {
            props.updateDevice(false)
        }
    }

    if (checklist) {
        return <Checklist />
    } else {
        return (
            <Switch>
                <Theme path="/presentations/:page" component={Presentations} layout={RisingStar} />
                <Theme path="/presentations" component={Presentations} layout={RisingStar} />
                <Theme path="/resources" component={Resources} layout={RisingStar} />
                <Route path="/" component={Splash} />
            </Switch>
        )
    }
}

const mapStateToProps = state => ({
    isMobile: state.mobile.isMobile
})

const mapDispatchToProps = dispatch => ({
    updateDevice: value => dispatch(updateDevice(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
