import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { motion, AnimatePresence } from "framer-motion"
import Modal from "../Modal"
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg"
import { gtmPushPageVisit, gtmButtonPushEvent } from "../../gtm"
import elearning from '../../assets/eLearning.jpeg'
import atlanta from '../../assets/SCAD-Atlanta.jpeg'
import lacoste from '../../assets/SCAD-Lacoste.jpeg'
import savannah from '../../assets/SCAD-Savannah.jpeg'
import StudentImage from '../../assets/student-life-page.png'

function StudentLife() {
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)
    const handleVideoClick = () => gtmButtonPushEvent("Engagement", "Video Click", "SCAD 2020 Orientation: Student Life")

    useEffect(() => {
        gtmPushPageVisit('/presentations/student-life', 'student life')
    }, [])

    return (
        <>
            <div className="presentations">
                <motion.div initial={{opacity: 0 }} animate={{ opacity: 1 }}>
                    {/* <div className="presentations__embed-container" onClick={handleVideoClick}>
                        <div className="presentations__embed-wrapper" />
                        <iframe title="SCAD 2020 Orientation: Student Life" className="presentations__embed-item" src="https://www.youtube.com/embed/Onnont1T55o"></iframe>
                    </div> */}
                    <img className="presentations__img" src={StudentImage}  alt=""/>
                    <div className="presentations__content-container">
                        <h2>Student life</h2>
                        <p>Find out what makes student life at SCAD so special and learn about opportunities for wellness, community, and connectivity.</p>
                        <p><strong>Learn more about:</strong></p>
                        <ul className="presentations__learn-more-list">
                            <li>
                                <Modal btnText="SCAD locations">
                                    <h3>SCAD locations</h3>
                                    <p>Tap into dynamic opportunities in Atlanta, find inspiration in charming Savannah, create amid the rich history of Lacoste, or study from anywhere in the world through SCADnow.</p>
                                    <ul className="presentations__video-list">
                                        <li>
                                            <h4> <a href="https://www.scad.edu/locations/atlanta" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/locations/atlanta')}>SCAD Atlanta</a></h4>
                                            {/* <a href="https://www.youtube.com/watch?v=x8Zorh9-l2Y&feature=emb_title" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.youtube.com/watch?v=x8Zorh9-l2Y&feature=emb_title')}>
                                                <img src="https://img.youtube.com/vi/x8Zorh9-l2Y/mqdefault.jpg" alt="SCAD Atlanta" />
                                            </a> */}
                                            <a href="https://www.scad.edu/locations/atlanta" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/locations/atlanta')}>
                                                <img src={atlanta} alt="SCAD Atlanta" />
                                            </a>
                                        </li>
                                        <li>
                                            <h4><a href="https://www.scad.edu/locations/lacoste" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/locations/lacoste')}>SCAD Lacoste</a></h4>
                                            {/* <a href="https://www.youtube.com/watch?v=naaHdf-aiy8&feature=emb_title" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.youtube.com/watch?v=naaHdf-aiy8&feature=emb_title')}>
                                                <img src="https://img.youtube.com/vi/naaHdf-aiy8/mqdefault.jpg" alt="Lacoste" />
                                            </a> */}
                                            <a href="https://www.scad.edu/locations/lacoste" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/locations/lacoste')}>
                                                <img src={lacoste} alt="Lacoste" />
                                            </a>
                                        </li>
                                        <li>
                                            <h4><a href="https://www.scad.edu/locations/savannah" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/locations/savannah')}>SCAD Savannah</a></h4>
                                            {/* <a href="https://www.youtube.com/watch?v=_BL1j3RL8t0&feature=emb_title" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.youtube.com/watch?v=_BL1j3RL8t0&feature=emb_title')}>
                                                <img src="https://img.youtube.com/vi/_BL1j3RL8t0/mqdefault.jpg" alt="Savannah" />
                                            </a> */}
                                            <a href="https://www.scad.edu/locations/savannah" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/locations/savannah')}>
                                                <img src={savannah} alt="Savannah" />
                                            </a>
                                        </li>
                                        <li>
                                            <h4><a href="https://www.scad.edu/academics/elearning" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/academics/elearning')}>SCADnow online learning</a></h4>
                                            {/* <a href="https://www.youtube.com/watch?v=EB3vD4gicuU&feature=emb_title" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.youtube.com/watch?v=EB3vD4gicuU&feature=emb_title')}>
                                                <img src="https://img.youtube.com/vi/EB3vD4gicuU/mqdefault.jpg" alt="SCAD eLearning" />
                                            </a> */}
                                            <a href="https://www.scad.edu/academics/elearning" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/academics/elearning')}>
                                                <img src={elearning} alt="SCAD SCADnow" />
                                            </a>
                                        </li>
                                    </ul>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="SCAD Card">
                                    <h3>SCAD Card</h3>
                                    <p>Your SCAD Card is one of your handiest tools. It's your official student ID and provides you entry to SCAD academic spaces, residence halls, museums, exclusive student events, and more. It's your library card for accessing one of the world's largest collections of art and design resources. You also use your SCAD Card to access your meal plan.</p>
                                    <p>Keep it with you and keep it safe. Report any lost or stolen SCAD Cards to the SCAD Department of University Safety. Lost or stolen cards can be suspended 24 hours a day to prevent fraudulent activity. Replacements can be issued at SCAD Card services.</p>
                                    <p>
                                        <strong>Atlanta</strong> <br />
                                        <a href="mailto:atlexpress@scad.edu">atlexpress@scad.edu</a> | 404.253.5441 <br />
                                        SCAD Card Office | 1600 Peachtree St. NW, 200
                                        </p>
                                    <p>
                                        <strong>Savannah</strong> <br />
                                        <a href="mailto:scadcard@scad.edu">scadcard@scad.edu</a> | 912.525.6153 <br />
                                        Victory Village | 1 W. Victory Drive
                                        </p>
                                    <p>
                                        Turner House Office <br />
                                        912.525.8440 <br />
                                        302 W. Boundary St.
                                        </p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Events and activities">
                                    <h3>Events and activities</h3>
                                    <h4>'Guests and Gusto'</h4>
                                    <p>Tune in to the SCAD virtual series <a href="https://www.scad.edu/guests-and-gusto" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/guests-and-gusto')}><em>Guests and Gusto</em></a> for conversations and content with leading cultural voices. Meet the creators and innovators remaking creative industries and get essential insight to guide your career in art, design, and entertainment.</p>
                                    <h4>Signature events</h4>
                                    <p>SCAD connects you with the people and experiences that will give you an edge as you launch your career. Each year, the university hosts signature events where you can showcase your work to your creative heroes, ask questions, and learn what it's like to work in your chosen field.</p>
                                    <p><a href="https://myevents.scad.edu/" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://myevents.scad.edu/')}>View upcoming events</a></p>
                                    <h4>Student involvement</h4>
                                    <p>Explore your interests, meet students of similar or different backgrounds, and expand your worldview. From student involvement activities to fitness and dining, life at SCAD is active and engaging. You'll flourish here.</p>
                                    <p>
                                        <strong>Atlanta</strong> <br />
                                        <a href="mailto:atlinvolvement@scad.edu">atlinvolvement@scad.edu</a> | 404.253.3334 <br />
                                        Student Success Center | 1600 Peachtree St. NW
                                        </p>
                                    <p>
                                        <strong>Savannah</strong> <br />
                                        <a href="mailto:involvement@scad.edu">involvement@scad.edu</a> | 912.525.4400 <br />
                                        Student Center | 120 Montgomery St.
                                        </p>
                                    <h4>Student media</h4>
                                    <p>The SCAD Student Media Center sponsors a student-run online newspaper, fashion blog, arts journal, comics blog, and an Atlanta-based radio station.</p>
                                    <p>
                                        <strong>Atlanta</strong> <br />
                                        <a href="mailto:studentmedia@scad.edu">studentmedia@scad.edu</a> | 404.253.3285 <br />
                                        SCAD House residence hall | 1280 Peachtree St.
                                        </p>
                                    <p>
                                        <strong>Savannah</strong> <br />
                                        <a href="mailto:studentmedia@scad.edu">studentmedia@scad.edu</a> | 404.253.3285 <br />
                                        Oglethorpe House | 201 W. Oglethorpe St.
                                        </p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Wellness at SCAD">
                                    <h3>Wellness at SCAD</h3>
                                    <h4>Bee Well</h4>
                                    <p>SCAD is committed to helping students build a strong foundation for success. <a href="https://www.scad.edu/beewell" target="_blank" rel="noopener noreferrer" onClick={handleClick('https://www.scad.edu/beewell')}>Bee Well's</a> focus on the three pillars of wellness — emotional, physical, and social — ensures students take a comprehensive look at their health.</p>
                                    <h4>Counseling services</h4>
                                    <p>SCAD counseling and student support services can assist you with a wide range of concerns, from boosting your coping skills to managing stress and anxiety. Counselors can help you on an individual and short-term basis, as well as organize support groups, make community referrals, plan educational workshops, and provide you with self-help resources.</p>
                                    <p>Services are free, and confidentiality is guaranteed within legal and ethical guidelines. To read more about available counseling options, check out <a href="https://myscad.scad.edu/" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://myscad.scad.edu/')}>MySCAD</a> > Resources tab > Department Directory channel > “Counseling and Student Support Services” link.</p>
                                    <p>
                                        <strong>Atlanta</strong> <br />
                                        Reach a counselor for crisis support 24 hours a day by calling 404.253.3210.
                                    </p>
                                    <p>
                                        <a href="mailto:atlcounseling@scad.edu">atlcounseling@scad.edu</a> | 404.253.3210 <br />
                                        Student Success Center | 1600 Peachtree St. NW
                                    </p>
                                    <p>
                                        <strong>Savannah</strong> <br />
                                        Reach a counselor for crisis support 24 hours a day by calling 877.959.3620.
                                    </p>
                                    <p>
                                        <a href="mailto:counseling@scad.edu">counseling@scad.edu</a> | 912.525.6971 <br />
                                        Deloitte Foundry | 516 Drayton St.
                                        </p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Office of Inclusion">
                                    <h3>Office of Inclusion</h3>
                                    <p>The Office of Inclusion works with SCAD students, faculty, and staff to ensure a culture in which all members of the university community feel welcomed, valued, respected, and empowered to thrive. <a href="https://www.scad.edu/about/scad-glance/inclusion-scad" target="_blank" onClick={() => handleClick('https://www.scad.edu/about/scad-glance/inclusion-scad')}>See how SCAD is creating positive change</a>.</p>
                                    <p>
                                        Lace Walker <br />
                                        Director of Inclusion <br />
                                        <a href="mailto:inclusion@scad.edu">inclusion@scad.edu</a>
                                    </p>
                                    <p>Share your thoughts at <a href="mailto:myidea@scad.edu">myidea@scad.edu</a>.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Tech support">
                                    <h3>Tech support</h3>
                                    <p>To help students communicate, learn, and share information, the SCAD network is accessible across campus. Technology support is also available if you need help or have questions. Use of SCAD technology is considered a privilege. You have certain responsibilities that are intended to keep the university, its student body, and especially your private information safe and secure.</p>
                                    <h4>SCAD Quick Start Technology Guide</h4>
                                    <p><a href="https://www.scad.edu/techguide" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/techguide')}>This quick start guide</a> has all the info you need to get up and running. From passwords and software to Wi-Fi and getting your game systems connected in your residence hall, you'll find answers to just about every tech question here.</p>
                                    <p><a href="mailto:techsupport@scad.edu">techsupport@scad.edu</a> | 912.525.4567</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Building access">
                                    <h3>Building access</h3>
                                    <p>Your SCAD Card is required for entry to all buildings, and you may not hold or prop open exterior doors for any other person. Take note of signage that may modify entry points as only an entrance or exit to control the flow of overall building traffic. Building hours can be found in MySCAD.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Books and supplies">
                                    <h3>Books and supplies </h3>
                                    <p>Find everything you need in one convenient location. Bookstores in Atlanta and Savannah carry textbooks, tech, and art supplies, as well as SCAD apparel and gifts. View store hours at the links below or shop online to get your purchases shipped right to your door.</p>
                                    <p>
                                        <strong>Atlanta</strong> <br />
                                        <a href="https://www.bkstr.com/scadatlantastore/store-hours" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.bkstr.com/scadatlantastore/store-hours')}>Ex Libris (SCAD Atlanta bookstore)</a> <br />
                                        404.253.6088 <br />
                                        1470 Spring Street NW <br />
                                        Atlanta, Georgia 30309 <br />
                                    </p>
                                    <p>
                                        <strong>Savannah</strong> <br />
                                        <a href="https://www.bkstr.com/savannahstore/store-hours" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.bkstr.com/savannahstore/store-hours')}>Ex Libris Bookstore</a> <br />
                                        912.525.7550 <br />
                                        228 Martin Luther King Jr. Blvd. <br />
                                        Savannah, Georgia 31401 <br />
                                    </p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Health services">
                                    <h3>Health services</h3>
                                    <h4>LiveHealth Online app</h4>
                                    <p>To make accessing quality health care services even easier, all SCAD students will be enrolled in the LiveHealth Online app. LiveHealth Online connects you to licensed, board-certified physicians who can help with the diagnosis and treatment of a wide array of minor illnesses such as sinus infections, cold and flu, UTIs, pink eye, and more from anywhere in the world. Get doctor access 24/7 with no scheduling, no travel, no waiting rooms, no hassle, and no cost to you.</p>
                                    <p>LiveHealth Online also provides access to appointment-based mental health counseling. Choose a therapist, pick a time that is convenient for you (seven days a week), and then talk to the therapist from the privacy of home or anywhere you feel comfortable.</p>
                                    <h4>Atlanta</h4>
                                    <p>If you need medical care, visit one of these providers. Each facility is close to campus and has a history of providing quality care for SCAD students.</p>
                                    <p>
                                        For in-person routine or urgent medical care: <br />
                                        <a href="https://www.wellstreet.com/office-locations/buckhead-south/" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.wellstreet.com/office-locations/buckhead-south/')}>Piedmont Urgent Care</a> <br />
                                        2292 Peachtree Rd. <br />
                                        404.996.0120
                                    </p>
                                    <p>
                                        <a href="https://www.piedmont.org/emergency-services/emergency-home" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.piedmont.org/emergency-services/emergency-home')}>Piedmont Hospital Emergency Room</a><br />
                                        1968 Peachtree Rd. NW <br />
                                        404.605.5000
                                    </p>

                                    <p>In a true emergency, visit the emergency room or dial 911.</p>
                                    <h4>Savannah</h4>
                                    {/* <p>The St. Joseph's/Candler health system operates the SCAD Center for Health, staffed by a nurse practitioner. Go there to be treated for colds, earaches, asthma, sinus infections, and small abrasions. Bring along your insurance card. Staff will even file insurance claims for you.</p>
                                    <p>Learn more about the <a href="https://www.scad.edu/healthcenter" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/healthcenter')}>SCAD Center for Health</a> and see operating hours and fees.</p>
                                    <p>912.819.1560 | 1900 Abercorn St.</p> */}
                                    <p>If you need medical care, visit one of these providers. Each facility is close to campus and has a history of providing quality care for SCAD students.</p>
                                    <p>
                                        For in-person routine or urgent medical care: <br />
                                        <a href="https://expercarehealth.com" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://expercarehealth.com')}>ExperCARE</a><br />
                                        818 Abercorn St. <br />
                                        912.800.0110
                                    </p>
                                    <p>
                                        In a true emergency, visit the emergency room or dial 911: <br />
                                        <a href="https://memorialhealth.com" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://memorialhealth.com')}>Memorial Health University Medical Center Emergency Room</a><br />
                                        4700 Waters Ave.<br />
                                        912.350.8000
                                    </p>
                                    <p>
                                        <a href="https://sjchs.org" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://sjchs.org')}>Candler Hospital Emergency Room</a><br />
                                        5353 Reynolds St.<br />
                                        912.819.6000
                                    </p>
                                    {/* <h4>Urgent care</h4>
                                    <p>If you've got more than a cold, it may be time to head to one of the urgent care centers available around the city. These centers can handle more complicated ailments and conduct a larger range of diagnostics.</p>
                                    <p>In a true emergency, visit the emergency room or dial 911.</p> */}
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Curfew">
                                    <h3>Curfew</h3>
                                    <p>The curfew for program students is 11 p.m. All students are required to be in their assigned residence hall at the start of curfew each evening so that attendance may be confirmed. After 11 p.m., all residential program students are required to remain in their assigned room until 6 a.m. A student with multiple curfew violations may be dismissed from the program.</p>
                                </Modal>
                            </li>
                        </ul>
                    </div>
                </motion.div>
            </div>
            <div className="presentations__bottom">
                <Link className="presentations__next-btn" to="/presentations/community-expectations-and-safety-resources">
                    <div>
                        <div className="presentations__next-btn-text--top">Next: Section 4</div>
                        <div className="presentations__next-btn-text--bottom">Community expectations and safety resources</div>
                    </div>
                    <ArrowIcon />
                </Link>
            </div>
        </>
    )
}

export default StudentLife
