import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg"
import { gtmPushPageVisit, gtmButtonPushEvent } from "../../gtm"
import Hello from '../../assets/date.png'
import WelcomeImage from '../../assets/Rising-Star-Welcome-Page-Image.jpg'
import { motion, AnimatePresence } from "framer-motion"

function Welcome() {
    const handleVideoClick = () => gtmButtonPushEvent("Engagement", "Video Click", "SCAD 2020 Orientation: Welcome")

    useEffect(() => {
        gtmPushPageVisit('/presentations/welcome', 'welcome')
    }, [])

    return (
        <>
            <div className="presentations">
                <motion.div initial={{opacity: 0 }} animate={{ opacity: 1 }}>
                    {/* <div className="presentations__embed-container">
                        <div className="presentations__embed-wrapper" />
                        <iframe title="SCAD 2020 Orientation: Welcome" className="presentations__embed-item" src="https://www.youtube.com/embed/_OP578qGn2Y"></iframe>
                    </div> */}
                    <img className="presentations__img" src={WelcomeImage}  alt=""/>
                    <div className="presentations__content-container presentations__welcome">
                        <div>
                            <div>
                                <img className="presentations__mobile-img" src={Hello} alt="" />
                            </div>
                            <h2>Welcome to SCAD!</h2>
                        </div>
                        <div>
                            <div className="presentations__hello">hello.</div>
                        </div>
                        <div>
                            <h2>Welcome to Rising Star!</h2>
                            <p>We’re glad you’re here, and we can’t wait to see what you’ll accomplish.</p>
                            <p>Throughout this site, you’ll be introduced to resources that will help you make the most of your time at SCAD. There’s lots to see and do, so let’s get started.</p>
                            <p>Your future starts now.</p>
                        </div>
                    </div>
                </motion.div>
            </div>
            <div className="presentations__bottom">
                <Link className="presentations__next-btn" to="/presentations/academics-advising#academics-advising">
                    <div>
                        <div className="presentations__next-btn-text--top">Next: Section 1</div>
                        <div className="presentations__next-btn-text--bottom">Academics and advising</div>
                    </div>
                    <ArrowIcon />
                </Link>
            </div>
        </>
    )
}

export default Welcome
