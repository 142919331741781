import React from 'react'

function Checklist() {
    return (
        <>
            <div className="checklist__container--top">
                <h1 className="checklist__header">
                    <span>Welcome to SCAD</span><br />
                    <span>Summer 2021</span><br />
                    <span>orientation</span><br />
                </h1>
                <p className="checklist__desc">Virtual experience opens June 14</p>
                <div className="checklist__bg">
                    <div className="checklist__bg--top-left"></div>
                    <div className="checklist__bg--top-right"></div>
                </div>
            </div>

            <div className="checklist__container--bottom">
                <div className="checklist__container--bottom-inner">
                    <h2 className="checklist__header--sub">Get a head start</h2>
                    <p>Review the pre-orientation checklist and your student guide to get an early start on orientation.</p>
                    <a className="checklist__btn" href="https://www.scad.edu/life/orientation/you-arrive/pre-orientation-checklist" target="_blank">View checklist and guides</a>
                </div>
            </div>
        </>
    )
}

export default Checklist