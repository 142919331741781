import React, { useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import Modal from "../Modal"
import { gtmPushPageVisit, gtmButtonPushEvent } from "../../gtm"
import IssoImage from '../../assets/isso-page.png'
import { Link } from "react-router-dom"
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg"

function Resources() {
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)
    const handleVideoClick = () => gtmButtonPushEvent("Engagement", "Video Click", "SCAD 2020 Orientation: International Student Services Office")

    useEffect(() => {
        gtmPushPageVisit('/presentations/international-student-services-office', 'international-student-services-office')
    }, [])

    return (
        <motion.div className="presentations" initial={{opacity: 0 }} animate={{ opacity: 1 }}>
            {/* <div className="presentations__embed-container" onClick={handleVideoClick}>
                <div className="presentations__embed-wrapper" />
                <iframe title="SCAD 2020 Orientation: International Student Services Office" className="presentations__embed-item" src="https://www.youtube.com/embed/DUxIa3IaeqE"></iframe>
            </div> */}
            <img className="presentations__img" src={IssoImage}  alt=""/>
            <div className="presentations__content-container">
                <h2>International Student Services Office</h2>
                <p>No matter where you're coming from, SCAD is your home away from home. Find out how SCAD assists you with adjusting to life in the U.S., managing immigration issues, and making connections.</p>
                <p><strong>Learn more about:</strong></p>
                <ul className="presentations__learn-more-list">
                    <li>
                        <Modal btnText="International Student Services Office">
                            <h3>International Student Services Office </h3>
                            <p>Advisers in the International Student Services Office (ISSO) will help you get settled at SCAD and acclimated to life in the U.S., and will also help you get set up with U.S. bank and cellphone services, a driver's license, and health care. The office also provides opportunities for you to share experiences with other international students. Intercultural clubs, socials, and discussion groups help you make connections with fellow students and increase your comfort living and studying in a new country.</p>
                            <p>The ISSO also works with you to ensure that you are aware of your responsibilities as an F-1 visa holder and helps you to understand enrollment requirements, work authorization restrictions, and more.</p>
                            <p>Stay connected by signing up for the <a href="https://www.scad.edu/life/student-services/international-student-information/atlanta-isso-newsletter-sign" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/life/student-services/international-student-information/atlanta-isso-newsletter-sign')}>Atlanta</a> and <a href="https://www.scad.edu/life/student-services/international-student-information/savannah-isso-newsletter-sign" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/life/student-services/international-student-information/savannah-isso-newsletter-sign')}>Savannah</a> ISSO newsletters to find out about SCAD events, day and weekend trips, upcoming immigration requirements, insurance due dates, and more.</p>
                            <p>
                                <strong>Atlanta</strong> <br />
                                <a href="mailto:issoatl@scad.edu">issoatl@scad.edu</a> | 404.253.3104 <br />
                                Student Success Center | 1600 Peachtree St. NW<br />
                                <a href="https://www.facebook.com/scadissoatl" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.facebook.com/scadissoatl')}>Atlanta ISSO Facebook</a> <br />
                                <a href="https://www.instagram.com/scadissoatl" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.instagram.com/scadissoatl')}>Atlanta ISSO Instagram</a>
                            </p>
                            <p>
                                <strong>Savannah</strong> <br />
                                <a href="mailto:isso@scad.edu">isso@scad.edu</a> | 912.525.7304 <br />
                                Norris Hall | 548 E. Broughton St. <br />
                                <a href="https://www.facebook.com/scadisso" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.facebook.com/scadisso')}>Savannah ISSO Facebook</a> <br />
                                <a href="https://www.instagram.com/scadissosav" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.instagram.com/scadissosav')}>Savannah ISSO Instagram</a>
                            </p>
                        </Modal>
                    </li>
                    <li>
                        <Modal btnText="SCAD Language Studio">
                            <h3>SCAD Language Studio</h3>
                            <p>The SCAD Language Studio oversees the English language program at SCAD, including free individual tutoring based on assessment results and instructor feedback. Get help to:</p>
                            <ul>
                                <li>Write academic papers</li>
                                <li>Make formal presentations</li>
                                <li>Read university-level materials</li>
                                <li>Comprehend academic lectures</li>
                                <li>Speak fluently in both formal and informal settings</li>
                                <li>Understand U.S. culture</li>
                            </ul>
                            <p><a href="https://www.scad.edu/life/international-student-life/english-language-support" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/life/international-student-life/english-language-support')}>Learn more</a></p>
                        </Modal>
                    </li>
                </ul>
            </div>
            <div className="presentations__bottom">
                <Link className="presentations__next-btn" to="/presentations/complete-orientation">
                    <div>
                        <div className="presentations__next-btn-text--top">Next: Section 6</div>
                        <div className="presentations__next-btn-text--bottom">Complete orientation</div>
                    </div>
                    <ArrowIcon />
                </Link>
            </div>
        </motion.div>
    )
}

export default Resources
