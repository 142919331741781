import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import Modal from "../Modal"
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg"
import { gtmPushPageVisit, gtmButtonPushEvent } from "../../gtm"
import { motion, AnimatePresence } from "framer-motion"
import CommunityImage from '../../assets/community-page.png'

function Community() {
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)
    const handleVideoClick = () => gtmButtonPushEvent("Engagement", "Video Click", "SCAD 2020 Orientation: Community Expectations and Safety Resources")

    useEffect(() => {
        gtmPushPageVisit('/presentations/community-expectations-and-safety-resources', 'community-expectations-and-safety-resources')
    }, [])

    return (
        <>
            <div className="presentations">
                <motion.div initial={{opacity: 0 }} animate={{ opacity: 1 }}>
                    {/* <div className="presentations__embed-container" onClick={handleVideoClick}>
                        <div className="presentations__embed-wrapper" />
                        <iframe title="SCAD 2020 Orientation: Community Expectations and Safety Resources" className="presentations__embed-item" src="https://www.youtube.com/embed/1I6T2O2OItE"></iframe>
                    </div> */}
                    <img className="presentations__img" src={CommunityImage}  alt=""/>
                    <div className="presentations__content-container">
                        <h2>Community expectations and safety resources</h2>
                        <p>Learn how SCAD creates a community of respect and find resources for staying safe and healthy.</p>
                        <p><strong>Learn more about:</strong></p>
                        <ul className="presentations__learn-more-list">
                            <li>
                                <Modal btnText="Student conduct">
                                    <h3>Student conduct</h3>
                                    <p>Student conduct policies, which outline how SCAD expects you to conduct yourself as a student, exist to protect you. Read and heed these policies.</p>
                                    <p>The Code of Student Conduct and the student handbook, both available to read in <a href="https://myscad.scad.edu/" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://myscad.scad.edu/')}>MySCAD</a>, are your manuals for how to conduct yourself while you're here. These carefully written policies cultivate a living and learning environment in which all students are respected and can grow personally, academically, and socially.</p>
                                    <ul>
                                        <li>No alcohol</li>
                                        <li>No tobacco</li>
                                        <li>No drugs</li>
                                        <li>No weapons</li>
                                        <li>Zero-tolerance policy</li>
                                    </ul>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Title IX">
                                    <h3>Title IX, harassment, misconduct, and assault</h3>
                                    <p>SCAD maintains a positive environment for students, faculty, and staff that is free of sex-based discrimination and sexual harassment, misconduct, and assault. The university does not tolerate violations and takes prompt remedial action to prevent and address such behavior and remedy its effects. Any violation of the sex-based discrimination and sexual harassment, misconduct, and assault policies should be reported to the university's Title IX coordinator. Students can also make confidential reports to the office of counseling and student support services.</p>
                                    <p><a href="mailto:titleixcoordinator@scad.edu" >titleixcoordinator@scad.edu</a></p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="University safety">
                                    <div className="presentations__embed-container" onClick={handleVideoClick}>
                                        <div className="presentations__embed-wrapper" />
                                        <iframe title="SCAD 2020 Orientation: Community Expectations and Safety Resources" className="presentations__embed-item" src="https://www.youtube.com/embed/6hIhPd_Ai1s"></iframe>
                                    </div>
                                    <br/><br/><br/>
                                    <h3>SCAD Department of University Safety</h3>
                                    <p>Student safety is a top priority at SCAD, and we've designed a dynamic safety program that pairs skilled staff with cutting-edge technology, so you'll be comfortable in all university buildings, getting to and from class, and traveling around downtown. SCAD Department of University Safety patrols are available 24/7, 365 days a year. These patrol officers also work in and around academic buildings and partner with SCAD community members to identify safety- and security-related issues and engage students, staff, and faculty to solve problems. Closed-circuit television systems and HD cameras provide 24/7 coverage and recording capabilities in all academic buildings and residence halls.</p>
                                    <p>
                                        <strong>Atlanta</strong> <br />
                                        <a href="mailto:atlsafety@scad.edu">atlsafety@scad.edu</a> | 404.253.3333 <br />
                                        1600 Peachtree St. NW
                                        </p>
                                    <p>
                                        <strong>Savannah</strong> <br />
                                        <a href="mailto:security@scad.edu">security@scad.edu</a> | 912.525.4500 <br />
                                        350 Bull St.
                                        </p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Safety resources">
                                    <h3>Safety resources</h3>
                                    <h4>SCAD alerts</h4>
                                    <p>When a crisis or emergency situation occurs, you'll receive updates via phone, text, and email from the SCAD Alert system. Make sure your contact information is up to date in <a href="https://myscad.scad.edu/" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://myscad.scad.edu/')}>MySCAD</a> > My Info tab > My Personal Info channel. Students can add up to two additional phone numbers to receive text alerts in the event of an emergency. If family members want to receive text-only SCAD Alerts, enter their cellphone numbers via <a href="https://myscad.scad.edu/" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://myscad.scad.edu/')}>MySCAD</a> &gt; My Info tab &gt; My Personal Info channel &gt; “Update Emergency Information” link. Then, click the “Add phone number to SCAD Alert” box.</p>
                                    <h4>LiveSafe app</h4>
                                    <p>The LiveSafe app, available for your <a href="https://itunes.apple.com/us/app/livesafe/id653666211?mt=8" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://itunes.apple.com/us/app/livesafe/id653666211?mt=8')}>iOS</a> and <a href="https://play.google.com/store/apps/details?id=com.livesafe.activities&hl=en" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://play.google.com/store/apps/details?id=com.livesafe.activities&hl=en')}>Android</a> devices, turns every smartphone on SCAD property into a personal safety device by integrating with its GPS, allowing users to report crime in real time. Download this app for an instant, direct connection with the SCAD Department of University Safety. Snap photos, anonymously report safety concerns, or engage in a live chat with university safety and security.</p>
                                    <h4>SafeRide</h4>
                                    <p>SCAD SafeRide is powered by TransLoc On-Demand. Download the TransLoc app to your <a href="https://itunes.apple.com/us/app/scad-saferide/id1438119065" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://itunes.apple.com/us/app/scad-saferide/id1438119065')}>iOS</a> or <a href="https://play.google.com/store/apps/details?id=com.doublemap.tapride.scadsaferide&hl=en_US" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://play.google.com/store/apps/details?id=com.doublemap.tapride.scadsaferide&hl=en_US')}>Android</a> device and request a ride by clicking the On-Demand tab.</p>
                                    <h4>Bus service</h4>
                                    <p>The Bee Line bus service provides transportation to and from classes in Atlanta and Savannah and continues to run after class hours to provide late-night transportation. The service is free of charge to students, faculty, and staff, and operates between open university facilities.</p>
                                    <p>All routes can be tracked via <a href="http://translocrider.com" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('http://translocrider.com')}>TransLoc</a>, either online or using the app, which also includes important messages about the Bee Line and other transportation.</p>
                                </Modal>
                            </li>
                            <li>
                                <Modal btnText="Extreme weather preparedness">
                                    <h3>Extreme weather preparedness</h3>
                                    <p>SCAD has an extensive hurricane preparedness plan in place, and the health and safety of students remains our primary concern. Upon an evacuation mandate, students will receive emails, phone calls, and text messages via SCAD Alert, the university's emergency notification system, and should also monitor local television and radio newscasts and scad.edu. Students should work with their families now to develop a self-evacuation plan. This may include friends, relatives, or roommates.</p>
                                    <p>To enable the university to assist with appropriate planning in the event of a possible evacuation, all students will be asked early in the quarter to designate their plan for self-evacuation. In the event of an approaching storm, students will be prompted to update that information if it has changed so that the university can make necessary arrangements to ensure the safety of our students. It's very important that students update their personal contact info in MySCAD at the beginning of each academic year, as well as after any mid-year change of address or phone number, to ensure timely notification in the event of an emergency.</p>
                                    <p>Students and their families are encouraged to review the full updated <a href="https://www.scad.edu/evacuationprocedure" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://www.scad.edu/evacuationprocedure')}>evacuation procedures</a>.</p>
                                </Modal>
                            </li>
                        </ul>
                    </div>
                </motion.div>
            </div>
            <div className="presentations__bottom">
                <Link className="presentations__next-btn" to="/presentations/international-student-services-office">
                    <div>
                        <div className="presentations__next-btn-text--top">Next: Section 5</div>
                        <div className="presentations__next-btn-text--bottom">International Student Services Office (ISSO)</div>
                    </div>
                    <ArrowIcon />
                </Link>
            </div>
        </>
    )
}

export default Community
